<template>
  <div class="purchase-page">
    <Title title="采购管理">
      <div class="b-m-t-s">
        <div class="batch-btn" @click="addPurchase" v-if="$authFunsProxy.manage">新增</div>
      </div>
    </Title>
    <div class="batch-list">
      <purchase-item
        style="margin-bottom:15px;"
        @action="itemAction"
        v-for="item in dataList"
        :data="item"
        :key="item.id"
      />
    </div>
    <fm-modal :width="800" v-model="status.form" :title="chooseData && chooseData.id ? '修改采购申报批次' : '新增采购申报批次'">
      <purchase-form ref="form" :data="chooseData" />
      <div slot="footer" class="modal-footer-btns">
        <fm-btn v-loadingx="loading.submit" @click="submit">{{chooseData && chooseData.id ? '保存' : '新增'}}</fm-btn>
        <fm-btn @click="status.form = false">取消</fm-btn>
      </div>
    </fm-modal>
    <fm-modal title="会议材料" :mask-closable="false" v-model="status.file" @cancel="status.file = false" width="600px">
      <file-manage
        v-if="$authFunsProxy.viewFile && chooseData"
        :file-data-ids="fileIds"
        @addFile="addFile"
        :funs="fileFuns"
        :sortable="fileFuns.upload"
        @updateSort="updateFileSort"
        @delFile="delFile"
      />
    </fm-modal>
  </div>
</template>

<script>
import Title from './components/cmp/title'
import FileManage from '@/components/base/FileManage'
import PurchaseForm from './components/purchase/form'
import PurchaseItem from './components/purchase/item'

import { purchaseBatchRequest } from '../../api'

export default {
  components: { Title, FileManage, PurchaseForm, PurchaseItem },
  props: {
    type: String
  },
  data () {
    return {
      chooseData: null,
      dataList: [],
      loading: {
        submit: false
      },
      status: {
        form: false,
        stages: false,
        file: false
      }
    }
  },
  watch: {
    type () {
      this.dataList = []
      this.loadData()
    }
  },
  computed: {
    fileIds () {
      return this.chooseData ? this.chooseData.fileIds : []
    },
    fileFuns () {
      if (this.chooseData) {
        return {
          get: this.$authFunsProxy.viewFile,
          upload: this.$authFunsProxy.upload,
          del: this.$authFunsProxy.delFile
        }
      } else {
        return {
          get: false,
          upload: false,
          del: false
        }
      }
    }
  },
  methods: {
    updateFileSort (fileIds) {
      this.chooseData.fileIds = fileIds
      purchaseBatchRequest.files(this.chooseData.id, {
        fileIds: fileIds.join(',')
      })
    },
    async addFile (data) {
      if (!this.chooseData.fileIds.includes(data.id)) {
        this.chooseData.fileIds.push(data.id)
        purchaseBatchRequest.files(this.chooseData.id, {
          fileIds: this.chooseData.fileIds.join(',')
        })
      }
    },
    async delFile (data) {
      const index = this.chooseData.fileIds.findIndex(v => data.id === v)
      if (index > -1) {
        this.chooseData.fileIds.splice(index, 1)
        purchaseBatchRequest.files(this.chooseData.id, {
          fileIds: this.chooseData.fileIds.join(',')
        })
      }
    },
    itemAction ({ action, data }) {
      if (action.key === 'details') {
        this.$router.push({
          name: 'internalcontrol.purchase.details', query: {
            id: data.id,
            status: data.status,
            type: this.type
          }
        })
      } else if (action.key === 'edit') {
        this.editBudget(data)
      } else if (action.key === 'del') {
        this.delItem(data)
      } else if (action.key === 'file') {
        this.chooseData = data
        this.status.file = true
      } else {
        this.statusSwitch(data, action)
      }
    },
    async statusSwitch (data, action) {
      const confirm = await this.$dialog.confirm({ title: '提示', content: '确定' + action.label + '?' })
      if (confirm) {
        await purchaseBatchRequest.switchStatus(data.id, {
          statusActionKey: action.key
        })
        this.$notice.success({ title: action.label + '成功' })
        this.loadData()
      }
    },
    addPurchase () {
      this.chooseData = {
        year: new Date(),
        batch: '',
        isJj: false,
        type: null,
        orgIds: this.$store.getters.orgList.map(v => v.data.id).join(','),
        leadUserIds: null,
        busOrgIds: null,
        purchaseLeadUserIds: null,
        deanUserIds: null
      }
      this.status.form = true
    },
    editBudget (data) {
      this.chooseData = data
      this.status.form = true
    },
    async delItem (data) {
      const confirm = await this.$dialog.confirm({ title: '提示', content: '确定要删除吗？' })
      if (confirm) {
        await purchaseBatchRequest.del(data.id)
        this.loadData()
      }
    },
    async submit () {
      if (this.$authFunsProxy.manage) {
        try {
          this.loading.submit = true
          const data = this.$refs.form.getData()
          delete data.orgs
          if (data.id) {
            await purchaseBatchRequest.update(data.id, data)
          } else {
            await purchaseBatchRequest.add(data)
          }
          this.loading.submit = false
          this.status.form = false
          this.$notice.success({ title: data.id ? '修改成功' : '新增成功'})
          this.loadData()
        } catch (error) {
          this.loading.submit = false
          this.$notice.error({
            title: '提示',
            desc: error.message
          })
        }
      }
    },
    async loadData () {
      let res = []
      if (this.type === 'handle') {
        res = await purchaseBatchRequest.getMy()
      } else {
        res = await purchaseBatchRequest.get()
      }
      this.dataList = res.map(item => {
        item.fileIds = (item.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        let actionCheck = {}
        item.actions.forEach(v => actionCheck[v.key] = true)
        item.actionCheck = actionCheck
        return item
      })
    }
  },
  mounted () {
    this.$store.dispatch('loadOrgList')
    this.$store.dispatch('loadUserList')
    this.loadData()
  }
}
</script>

<style lang="less" scoped>
.purchase-page {
  background: #EEF3FA;
  width: 100%;
  height: 100%;
  padding: 0px 15px 12px;
  .b-m-t-s {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .batch-btn {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    cursor: pointer;
    color: #FFFFFF;
    background: #3776ED;
    border-radius: 4px;
    height: 26px;
    line-height: 26px;
    padding: 0 18px;
  }
  .batch-list {
    margin-top: 14px;
    height: calc(100% - 44px);
    overflow-y: auto;
  }
}
</style>